module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"title":"Liva Insurance","type":"website","locale":"en_US","description":"Cheap Car Insurance in UAE by Liva Insurance. Get the best Third Party Car Insurance  in UAE","images":[{"url":"https://optimusapp.lemonhq.io/images/livaLogo.png","alt":"Liva"}],"site_name":"Liva"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
